<template>
  <v-container>
    <base-material-card
      color="primary"
      icon="mdi-file-tree"
      inline
      class="px-5 py-4 ml-0 pb-10"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Logs da importação #{{ importacao.id }}
        </div>
      </template>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            style="float: right !important"
            class="mt-n9"
            v-bind="attrs"
            v-on="on"
          >
            <v-btn
              id="importacao-indger-logs-tecnicos"
              icon
              color="black"
              @click="baixarLogsTecnicos()"
              :loading="loadingLogsTecnicos"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="22"> mdi-download-circle-outline </v-icon>
            </v-btn>
          </div>
        </template>
        Clique aqui para baixar os logs técnicos
      </v-tooltip>
      <v-divider class="my-4" />
      <span id="importacao-indger-logs-first-sec">
        <v-row>
          <v-col cols="6">
            <label class="d-block mb-1">Título da importação:</label>
            <v-label>{{ titulo }}</v-label>
          </v-col>
          <v-col cols="6">
            <label class="d-block mb-1">Empresa:</label>
            <v-label>{{ empresa }}</v-label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <label class="d-block mb-1">Descrição:</label>
            <v-label>{{ descricao ? descricao : '-' }}</v-label>
          </v-col>
          <v-col cols="6">
            <label class="d-block mb-1">Mês dos dados:</label>
            <v-label>
              {{ dataRegistro | formatToMonth }} v{{ versao }}
            </v-label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <label class="d-block mb-1">Criado por:</label>
            <v-label>{{ criado_por }} em  {{ criado_em }}</v-label>
          </v-col>
          <v-col cols="6">
            <label class="d-block mb-1">Codificação dos arquivos:</label>
            <v-label>{{ encoding }}</v-label>
          </v-col>
          <v-col cols="6">
            <label class="d-block mb-1">
              Logs da importação:
            </label>
            <v-tooltip v-if="importacao.log_importacao_existe" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  id="importacao-dados-logs-valid"
                  color="gray"
                  outlined
                  small
                  @click="baixarLogs()"
                  :loading="loadingLogs"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>
                    mdi-download-circle-outline
                  </v-icon>
                  Baixar
                </v-btn>
              </template>
              Clique aqui para baixar os logs
            </v-tooltip>
            <v-label v-else>-</v-label>
          </v-col>
          <v-col cols="6">
            <label class="d-block mb-1">
              Arquivo
            </label>
            <v-tooltip  bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  id="importacao-dados-logs-valid"
                  color="gray"
                  outlined
                  small
                  @click="downloadFile()"
                  :loading="loadingFile"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>
                    mdi-download-circle-outline
                  </v-icon>
                  Baixar
                </v-btn>
              </template>
              Clique aqui para baixar o arquivo de importação
            </v-tooltip>
          </v-col>
        </v-row>
      </span>
      <v-divider class="mt-2 mb-2" />
      <v-row v-if="importacao.status">
        <v-col cols="12">
          <import-status-card
            id="import-status-card-ib"
            :statusInicial="importacao.status"
            :numeroEntidades="5"
            :numeroItensProcessadosInicial="
              importacao.numero_entidades_processados
            "
            :isPreImport="false"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="py-0"
        >
          <entities-import-tab
            :entidades="entidades"
            :contadores="contadores"
            :versao="versao"
          />
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>

import ImportacoesIndgerService from '@/services/ImportacoesIndgerService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import tourMixins from '@/mixins/tourMixins';

export default {
  name: "ImportacoesIndgerLogs",
  
  mixins: [refreshDataMixins, tourMixins],

  components: {
    PreImportStatusCard: () => import('@/components/administracao/importacoes-indger/ImportStatusCard'),
    ImportStatusCard: () => import('@/components/administracao/importacoes-indger/ImportStatusCard'),
    EntitiesImportTab: () => import('@/components/administracao/importacoes-indger/EntitiesImportTab')
  },

  data: () => ({
    empresa: '',
    titulo: '',
    dataRegistro: '',
    versao: null,
    descricao: 0,
    encoding: '',
    importacao: {},
    entidades: [
      "COMERCIAL",
      "SERVICOS",
      "TEC_AL",
      "TEC_LD",
      "TEC_SUB"
    ],
    contadores: {},
    criado_por: "",
    criado_em: "",
    loadingLogsTecnicos: false,
    loadingLogs: false,
    loadingFile: false,
    // stepTourSummaryReleased: false
  }),

  async created() {
    await this.refreshData(this.getLogsImportacao);
  },

  computed: {
    arrTour() {
      let tour = [
        {
          element: '#importacao-indger-logs-first-sec',
          intro:
            'Nessa seção é possivel visualizar as informações basicas da importação.',
          scrollTo: 'tooltip',
          position: 'bottom'
        },
        {
          element: '#importacao-indger-logs-tecnicos',
          intro:
            "Clicando neste botão você poderá realizar o download dos log's técnicos da importação, esse log é mais conveniente para desenvolvedores.",
          scrollTo: 'tooltip',
          position: 'bottom'
        }
      ];

      tour.push(
        {
          element: '#pre-import-status-card-ib',
          intro:
            'Aqui é possivel visualizar o status da pre-importação, qtd. total de arquivos e entidades processadas e progresso atual desta etapa.',
          scrollTo: 'tooltip',
          position: 'bottom'
        },
        {
          element: '#import-status-card-ib',
          intro:
            'Aqui é possivel visualizar o status da importação, qtd. total de entidades processadas e progresso atual desta etapa.',
          scrollTo: 'tooltip',
          position: 'bottom'
        }
      );

      // if (this.stepTourSummaryReleased) {
      //   tour.push({
      //     element: '#import-indger-summary',
      //     intro:
      //       'Aqui é possivel visualizar um breve resumo do processo de importação.',
      //     scrollTo: 'tooltip',
      //     position: 'top'
      //   });
      // }

      tour.push(
        {
          element: '#menu-esquerdo-entidades-ib',
          intro:
            'Aqui ficará listado todas entidades que foram registradas para serem importadas, clicando sobre elas você consegue ver mais detalhes sobre cada uma.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#importacao-indger-table-desc-ent',
          intro:
            'Aqui será possivel visualizar os arquivos que foram enviados para compor cada uma das entidades e seu status atual.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#importacao-indger-column-df',
          intro:
            'Clicando sobre essa coluna é possivel realizar o download do arquivo, porem caso essa importação tenha sido criada a 60 dias atrás ou mais, não será possível baixar esse arquivo.',
          scrollTo: 'tooltip',
          position: 'right'
        }
      );

      return tour;
    }
  },

  methods: {
    getLogsImportacao() {
      if (!this.$route.params.id) {
        return;
      }

      ImportacoesIndgerService.getLogsImportacao(this.$route.params.id)
        .then(res => {
          this.empresa = res.company.name;
          this.titulo = res.titulo;
          this.dataRegistro = res.indger_version.data_month;
          this.versao = res.indger_version.version;
          this.descricao = res.descricao;
          this.encoding = res.encoding;
          this.importacao = {
            id: res.id,
            status: res.status,
            numero_entidades_processados: res.numero_entidades_processadas ?? 0,
            numero_entidades_processadas_com_sucesso: res.numero_entidades_processadas_com_sucesso ?? 0,
            log_importacao_existe: res.log_importacao_existe ?? false
          };
          for(let entidade in res.contadores){
            this.contadores[entidade] = res.contadores[entidade]
            this.contadores[entidade]['statusValidacao'] = res.status_validacao[entidade]
          }
          this.criado_em = res.created_at;
          this.criado_por = res.user.name;

          // this.iniciarTourPagina();
        }).catch(err => {
          console.error(err);
          this.$toast.error('Falha ao recuperar dados da importação.', '', { position: 'topRight' });
        });
    },

    baixarLogsTecnicos() {
      this.loadingLogsTecnicos = true;

      ImportacoesIndgerService.baixarLogsTecnicos(this.importacao.id)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `logs-tecnicos-importacao-${this.importacao.id}.log`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error('Falha no download dos logs técnicos.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingLogsTecnicos = false));
    },

    baixarLogs() {
      this.loadingLogs = true;

      ImportacoesIndgerService
        .baixarLogs(this.importacao.id)
        .then(res => {

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `logs-importacao-${this.importacao.id}.zip`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(() => {
            this.$toast.error('Falha no download dos logs de importação.', '', { position: 'topRight' });
        })
        .finally(() => this.loadingLogs = false);
    },
    downloadFile() {
      this.loadingFile = true;

      ImportacoesIndgerService
        .download(this.importacao.id)
        .then(res => {
           window.open(res, '_blank').focus();
        })
        .catch(() => {
            this.$toast.error('Falha no download do arquivo de importação.', '', { position: 'topRight' });
        })
        .finally(() => this.loadingFile = false);
    },

    isEmptyObject(obj) {
      return JSON.stringify(obj) === '{}';
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      this.iniciarTour(this.arrTour);
    }
  }
};
</script>
